import { format } from 'date-fns';

function toCSV(fields, data) {
  const result = [fields.join(',')];
  data.forEach((row) => {
    result.push(
      fields.map((fld) => (fld === 'timestamp'
        ? format(new Date(row[fld]), 'yyyy-MM-dd HH:mm:ss')
        : row[fld].toFixed(0))),
    );
  });
  return result.join('\n');
}

export default toCSV;
