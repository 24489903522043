import { useCallback } from 'react';
import { last } from 'lodash';
import { format } from 'date-fns';
import useDatabase from './useDatabase';
import { DATA_FIELDS } from './useOrientation';

function useExport() {
  const { getAll } = useDatabase('orientation');
  return useCallback(async () => {
    const data = await getAll();
    if (data.length === 0) {
      return null;
    }
    const date = new Date(last(data).timestamp);
    const title = `skatelaps-${format(date, 'yyMMdd-HHmm')}`;
    return {
      fields: ['timestamp', ...DATA_FIELDS],
      data,
      title,
    };
  }, []);
}

export default useExport;
