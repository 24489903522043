import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useRecorder from '../hooks/useRecorder';
import useDatabase from '../hooks/useDatabase';
import useWakeLock from '../hooks/useWakeLock';
import Download from './Download';

function Controls(props) {
  const { table, setTable } = props;
  const { clear } = useDatabase('orientation');
  const setLocked = useWakeLock()[1];

  function handleData(data) {
    setTable((t) => [data, ...t.slice(0, 19)]);
  }
  const {
    running, start, stop,
  } = useRecorder(handleData);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (!body) {
      return;
    }
    const { classList } = body;
    if (running) {
      classList.add('dark');
      setLocked(true);
    } else {
      classList.remove('dark');
      setLocked(false);
    }
  }, [running]);

  function reset() {
    // eslint-disable-next-line no-alert
    if (window.confirm('Clear all data?')) {
      clear().then(() => setTable([]));
    }
  }

  return (
    <div className="d-flex justify-content-between">
      <div>
        {running
          ? <button type="button" onClick={stop} className="btn btn-warning">Stop</button>
          : <button type="button" onClick={start} className="btn btn-success">Start</button>}
      </div>
      <div>
        <Download />
        <button type="button" onClick={reset} className="btn btn-danger ms-3" disabled={table.length === 0}>
          Reset
        </button>
      </div>
    </div>
  );
}

Controls.propTypes = {
  table: PropTypes.array.isRequired,
  setTable: PropTypes.func.isRequired,
};

export default Controls;
