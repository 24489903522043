import { useCallback, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import useDatabase from './useDatabase';
import useOrientation from './useOrientation';

function useRecorder(onData) {
  const [handle, setHandle] = useState(null);
  const data = useOrientation();
  const { add } = useDatabase('orientation');
  const dataRef = useRef();
  dataRef.current = data;

  const recordData = () => {
    if (isEmpty(dataRef.current)) {
      return null;
    }
    const timedData = { timestamp: Date.now(), ...dataRef.current };
    onData(timedData);
    return add(timedData);
  };

  function start() {
    if (!handle) {
      setHandle(window.setInterval(recordData, 1000));
    }
  }

  function stop() {
    if (handle) {
      window.clearInterval(handle);
      setHandle(false);
    }
  }

  return {
    running: Boolean(handle),
    start: useCallback(start, [handle]),
    stop: useCallback(stop, [handle]),
  };
}

export default useRecorder;
