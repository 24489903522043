import { useEffect, useState } from 'react';
import { pick } from 'lodash';

export const DATA_FIELDS = ['alpha', 'beta', 'gamma'];

function useOrientation() {
  // const db = useDatabase(DB_NAME, DB_VERSION, DB_STORES);
  const [data, setData] = useState({});

  function orientationListener(ev) {
    setData(pick(ev, DATA_FIELDS));
  }

  useEffect(() => {
    window.addEventListener('deviceorientation', orientationListener);
    return () => window.removeEventListener('deviceorientation', orientationListener);
  }, []);

  return data;
}

export default useOrientation;
