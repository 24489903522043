import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { DATA_FIELDS } from '../hooks/useOrientation';

function Table(props) {
  const { data } = props;
  return (
    <table className="table text-center">
      <thead>
        <tr>
          <th>timestamp</th>
          {DATA_FIELDS.map((fld) => (
            <th key={fld}>{fld}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id}>
            <td>{format(new Date(row.timestamp), 'HH:mm:ss')}</td>
            {DATA_FIELDS.map((fld) => (
              <td key={fld}>{Math.round(row[fld])}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Table;
