import React, { useState } from 'react';
import './sentry';
import './App.css';
import Table from './components/Table';
import Controls from './components/Controls';

function App() {
  const [table, setTable] = useState([]);

  return (
    <div className="container-sm">
      <h1>Schaatshorloge Lap Counter</h1>
      <Controls table={table} setTable={setTable} />
      <Table data={table} />
    </div>
  );
}

export default App;
