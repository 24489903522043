import { useEffect, useState } from 'react';

function useWakeLock(initial) {
  const [lock, setLock] = useState(null);
  const locked = lock && !lock.released;

  function request() {
    if (!locked) {
      navigator.wakeLock.request('screen').then(setLock);
    }
  }

  function release() {
    if (locked) {
      lock.release().then(() => setLock(null));
    }
  }

  const setWakeLock = (flag) => (flag ? request() : release());

  useEffect(() => {
    if (initial) {
      request();
    }
    return release;
  }, [initial]);
  return [locked, setWakeLock];
}

export default useWakeLock;
