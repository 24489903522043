const storeConfig = { keyPath: 'id', autoIncrement: true };

function makeSchema(list) {
  return list.map((name) => ({ name }));
}

export const DBConfig = {
  name: 'recorder',
  version: 1,
  objectStoresMeta: [
    {
      store: 'orientation',
      storeConfig,
      storeSchema: makeSchema(['timestamp', 'absolute', 'alpha', 'beta', 'gamma']),
    },
  ],
};
