import React from 'react';
// import PropTypes from 'prop-types';
import useExport from '../../hooks/useExport';
import toCSV from './toCSV';
import saveFile from './saveFile';

function Download() {
  const getExport = useExport();
  const download = async (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const data = await getExport();
    if (!data) {
      // eslint-disable-next-line no-alert
      alert('No data');
      return;
    }
    const dataString = toCSV(data.fields, data.data);
    const filename = `${data.title}.csv`;
    saveFile(filename, dataString);
  };
  return (
    <button type="button" onClick={download} className="btn btn-secondary" title="Download as CSV">
      Download
    </button>
  );
}

export default Download;
